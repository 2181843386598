import classNames from "classnames"
import { ethers } from "ethers"
import { useEffect, useState, useRef } from "react"
import { append } from 'ramda'
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { createPerspective, labelForUser, compressAddr, isSomething, getStorage } from "./lib/utils"
import * as st8 from './state'
import './UserPage.scss'
import ViewingModeMessage from "./ViewingModeMessage"
import { useHistory } from "react-router-dom"
import IcDetails from "./IcDetails"
import MyIcList from "./MyIcList"

function UserPage ({ onPerspectiveChange }) {
  const history = useHistory()
  const [perspectives, setPerspectives] = useRecoilState(st8.perspectivesState)
  const [icFileServerUser, setIcFileServerUser] = useRecoilState(st8.icFileServerUserState)
  const icFileServerUrl = useRecoilValue(st8.icFileServerUrlState)
  const [openI, setOpenI] = useState()
  const [pastIcs, setPastIcs] = useState([])
  const [somethingHappening, setSomethingHappening] = useRecoilState(st8.thingsHappeningState)
  const exportData = useSetRecoilState(st8.icExportDataState)
  const userLabel = labelForUser(icFileServerUser, perspectives)
  const inputRef = useRef()
  // init
  useEffect(() => {
    const go = async () => {
      const pastIcs = await getStorage('pastIcs')
      setPastIcs(pastIcs || [])
    }
    go()
  }, [setPastIcs])
  useEffect(() => {
    if(isSomething(openI)) {
      setTimeout(() => inputRef.current && inputRef.current.focus(), 100)
    }
  }, [openI])

  const persp = perspectives.find(p => p.address === icFileServerUser) || {}
  const icServers = persp.icServers || []

  return (
    <div className='user-page'>
      <a className='close' onClick={() => setSomethingHappening(somethingHappening.filter(s => s !== 'perspectives'))}></a>
      <ViewingModeMessage />
      <div className='section'>
        <div className='current-persp'>
          <IcDetails p={persp} />
        </div>
        <MyIcList 
          onPerspectiveChange={p => {
            setIcFileServerUser(p.address)
            if(onPerspectiveChange) {
              onPerspectiveChange(p)
            }
            history.push('/')
            setSomethingHappening(somethingHappening.filter(s => s !== 'perspectives'))
          }}
        />
      </div>
      {/* <div className='section'>
        {pastIcs.length > 0 && <h3>Past ICs</h3>}
        <ul>
          {pastIcs.map((p, i) => <a key={i} href={`/?ic=${p}`}><li>{compressAddr(p.replace(/https?:\/\//, '').replace('/index.ic', ''), 12, 5)}</li></a>)}
        </ul>
        <h3>IC File Server</h3>
        <IcFileServerMessage />
      </div> */}
    </div>
  )
}

export default UserPage