import './App.scss'
import {
  RecoilRoot
} from 'recoil'
import {
  HashRouter as Router,
} from 'react-router-dom'
import AppWrap from './AppWrap'


function App () {
  return (
    <RecoilRoot>
      <Router>
        <AppWrap />
      </Router>
    </RecoilRoot>
  )
}

export default App
