import './Tag.scss'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { ReactComponent as X } from './assets/x.svg'
import { isImage, isSomething, seePathForTags, srcForTag } from './lib/utils'
import { useHistory, useParams } from 'react-router'
import { not } from 'ramda'
import Stats from './Stats'
import * as st8 from './state'
import { useRecoilValue } from 'recoil'

function Tag ({ tag, link, onYes, onNo, onSelect, className, onAdd, selected }) {
  const params = useParams()
  const history = useHistory()
  const [_selected, setSelected] = useState(selected)
  const icImages = useRecoilValue(st8.icImagesState)
  const tagParams = params && params[0] && params[0].split('/').map(decodeURIComponent).sort((a, b) => a.localeCompare(b))
  let content = <span className='tag-tag'>{tag.tag}</span>
  if (icImages.includes(tag.tag)) {
    content = <span className='img' style={{ backgroundImage: `url(${srcForTag(tag.tag)})`}} />
  }
  const [open, setOpen] = useState(false)

  if (link) {
    content = <Link className='content' to={`/c/${encodeURIComponent(tag.tag)}`}>
      {content}
    </Link>
  // } else if (onSelect) {
  //   content = <a onClick={() => setSelected(not)} className='content'>{content}</a>
  } else {
    content = <span className='content'>{content}</span>
  }
  let add = null
  if (tagParams) {
    add = <Link className='add' to={seePathForTags(tagParams.concat(tag.tag))}>+</Link>
    if (onAdd) {
      add = <a className='add' onClick={() => onAdd(tag)}>+</a>
    }
  }
  return <div className={classNames('tag', { 
    img: icImages.includes(tag.tag),
    yes: tag.me === '+', 
    no: tag.me === '-', 
    neg: tag.sum && tag.sum < 0,
    ack: !!tag.me, 
    open 
  }, `c-${tag.count}`, className)}>
    <div className='top-wrap'>
      {onSelect && <div className={classNames({ selected }, 'check-wrap')}><input checked={selected} type='checkbox' onChange={e => {
        onSelect && onSelect(e.target.checked)
      }} /></div>}
      <a onClick={() => setOpen(!open)} className='a-open' />
      {onYes &&
        <a className='yes' onClick={() => {
          setOpen(false)
          if (tag.me === '+') return
          onYes(tag)
        }}><i /></a>
      }
      {onNo &&
        <a className='no' onClick={() => {
          setOpen(false)
          if (tag.me === '-') return
          onNo(tag)
        }}><X /></a>
      }
      {content}
      {add}
    </div>
    <div className='stats-wrap'>
      <Stats
        data={Object.assign({
          users: tag.count,
          percent: Math.floor(tag.yes / tag.count * 100)
        }, tag)}
      />
    </div>
  </div>
}

export default Tag
