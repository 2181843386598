import { useEffect, useState } from 'react'
import { copyToClipboard } from './lib/utils'

export default function CopyLink ({ text, children }) {
  const [copied, setCopied] = useState(false)
  useEffect(() => {
    // reset after 2 seconds
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
  }, [copied])
  return (
    <a className='copy-link' onClick={() => {
      copyToClipboard(text)
      setCopied(true)
    }}>{copied ? 'copied!' : children}</a>
  )

}