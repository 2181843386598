import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil"
import { compressAddr, createPerspective, getStorage, labelForUser, setStorage } from "./lib/utils"
import { icFileServerUrlState, perspectivesState, isMineFsState, icConnectedState, icFileServerUserState, icSettingsState, icExportDataState, thingsHappeningState } from "./state"
import './UserInfo.scss'
import { append, contains, not } from "ramda"

function UserInfo ({ }) {
  const icSettings = useRecoilValue(icSettingsState)
  const [icFileServerUser, setIcFileServerUser] = useRecoilState(icFileServerUserState)
  const [perspectives, setPerspectives] = useRecoilState(perspectivesState)
  const [somethingHappening, setSomethingHappening] = useRecoilState(thingsHappeningState)
  const upDown = icFileServerUser ? <span><a onClick={() => {
    if (contains('perspectives', somethingHappening)) {
      setSomethingHappening(somethingHappening.filter(a => a !== 'perspectives'))
    } else {
      setSomethingHappening(append('perspectives')) 
    }
  }}>🗄</a></span> : icSettings.id

  if (!icFileServerUser) return null
  return (
    <span className='user-info'>
      {upDown}
    </span> 
  )
}
export default UserInfo
