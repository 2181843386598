import classNames from "classnames"
import { ethers } from "ethers"
import { useEffect, useState, useRef } from "react"
import { append } from 'ramda'
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { setIdb, createPerspective, labelForUser, compressAddr, isSomething, getStorage } from "./lib/utils"
import * as st8 from './state'
import './UserPage.scss'
import ViewingModeMessage from "./ViewingModeMessage"
import { useHistory } from "react-router-dom"
import IcDetails from "./IcDetails"
import "./MyIcList.scss"

export default function MyIcList ({ onPerspectiveChange }) {
  const history = useHistory()
  const [perspectives, setPerspectives] = useRecoilState(st8.perspectivesState)
  const [icFileServerUser, setIcFileServerUser] = useRecoilState(st8.icFileServerUserState)
  const icFileServerUrl = useRecoilValue(st8.icFileServerUrlState)
  const [openI, setOpenI] = useState()
  const [pastIcs, setPastIcs] = useState([])
  const [somethingHappening, setSomethingHappening] = useRecoilState(st8.thingsHappeningState)
  const exportData = useSetRecoilState(st8.icExportDataState)
  const userLabel = labelForUser(icFileServerUser, perspectives)
  const inputRef = useRef()

  useEffect(() => {
    if(isSomething(openI)) {
      setTimeout(() => inputRef.current && inputRef.current.focus(), 100)
    }
  }, [openI])

  const persp = perspectives.find(p => p.address === icFileServerUser) || {}
  const icServers = persp.icServers || []

  return (
    <div className='my-ic-list'>
      <h3 className='text-xl mt-6 mb-2'>
        <i className='fa fa-folder-open' />
        &nbsp; 
        <a className='plus always' onClick={() => {
          const persp = createPerspective()
          const newPersps = append(persp, perspectives)
          setPerspectives(newPersps)
          setOpenI(newPersps.length - 1)
        }}>+</a>
      </h3>
      <ul className='perspectives'>
        {perspectives.map((p, i) => {
          const selected = openI === i
          let theOpen = null
          if (openI === i) {
            theOpen = <IcDetails p={p} />
          }
          return (
            <li key={p.address} className={classNames('persp', { open: openI === i, metamask: !p.private, public: p.server })}>
              <span className='star'>{p.address === icFileServerUser ? ' ⭐️ ' : ''}</span> 
              <a onClick={() => {
                if(onPerspectiveChange) {
                  onPerspectiveChange(p)
                }
              }}>
                {p.label || compressAddr(p.address)}
                {p.server && <span className='server'>@{p.server.name}</span>}
              </a>
              {selected ? <span className='addr hint'> - {selected ? p.address : compressAddr(p.address)}</span> : ''}
              &nbsp;
              <a className='icon fa fa-gear pop' onClick={() => setOpenI(selected ? null : i)}>️</a>
              <div className='label-wrap'>
                <h3>Collection Name</h3>
                <input 
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      setOpenI(null)
                    }
                  }}
                  ref={selected ? inputRef : null}   
                  type='text' 
                  placeholder='give your IC a name'
                  value={p.label || ''} 
                  onChange={evt => {
                    console.log(evt.target.value)
                    const ps = perspectives.map(p2 => {
                      if (p2.address === p.address) {
                        p2 = Object.assign({}, p2, { label: evt.target.value })
                      }
                      return p2
                    })
                    setPerspectives(ps)
                  }}
                />
              </div>
              {theOpen}
              <a className='x' onClick={() => {
                if (confirm('Are you sure you want to delete this perspective?')) {
                  setPerspectives(perspectives.filter(p2 => p2.address !== p.address))
                  setIdb(p.address + '-ic', null)
                }
              }}>delete</a>
            </li>
          )
        })}
        {window.ethereum && perspectives.filter(p => !p.private).length < 1 && <li>
            <a className="pop" onClick={async () => {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner()
            const addr = await signer.getAddress()
            setIcFileServerUser(addr)
            const persp = {
              address: addr
            }
            const newPersps = append(persp, perspectives)
            setPerspectives(newPersps)
            setOpenI(newPersps.length - 1)
          }}>metamask</a>
        </li>}
      </ul>
    </div>
  )
}
