import { Link } from 'react-router-dom'
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import { labelForUser } from './lib/utils'
import * as st8 from './state'
import './IcLink.scss'
import { toPairs } from 'ramda'


export default function IcLink ({}) {
  const [icFileServerUser, setIcFileServerUser] = useRecoilState(st8.icFileServerUserState)
  const [perspectives, setPerspectives] = useRecoilState(st8.perspectivesState)
  const icfsConfig = useRecoilValue(st8.icfsConfigState)
  const icSettings = useRecoilValue(st8.icSettingsState)
  const exportData = useSetRecoilState(st8.icExportDataState)
  const links = icSettings.links && toPairs(icSettings.links)
  return (
    <div className='ic-link'>
      <Link to='/'>
        <span className='username'>{labelForUser(icFileServerUser, perspectives)}</span>
        {icfsConfig && icfsConfig.name && `@${icfsConfig.name}`}
      </Link>
      {links && links.length > 1 && <a className='fa fa-rotate-right' onClick={() => exportData('refresh')} />}
    </div>
  )
}