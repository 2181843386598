import './Sidebar.scss'
import { useState } from 'react'
import IpfsInfo from './IpfsInfo'
import UserInfo from './UserInfo'
import DbName from './DbName'
import classNames from 'classnames'
import { useRecoilState, useRecoilValue } from 'recoil'
import { thingsHappeningState, breadcrumbState, filterIState, icDataState, ipfsState, lensState, sortIState } from './state'
import { SORTS, FILTERS } from './lib/utils'
import TagInput from './TagInput'
import Tag from './Tag'
import { Link, useHistory } from 'react-router-dom'
import { sort } from 'ramda'
import DbAvatar from './DbAvatar'
import LensStats from './LensStats'
import { useLocation } from 'react-router-dom'
import ICStats from './ICStats'

function Sidebar () {
  const [filterI, setFilterI] = useRecoilState(filterIState)
  const [sortI, setSortI] = useRecoilState(sortIState)
  const data = useRecoilValue(icDataState)
  const [lens, setLens] = useRecoilState(lensState)
  const ipfs = useRecoilValue(ipfsState)
  const history = useHistory()
  const location = useLocation()
  const bc = useRecoilValue(breadcrumbState)
  const [somethingHappening, setSomethingHappening] = useRecoilState(thingsHappeningState)
  const [search, setSearch] = useState(false)
  let tab = <>
    <DbName />
  </>
  if (lens) {
    tab = <div>
      <LensStats />
    </div>
  }
  return <div className='sidebar'>
    <div className='filters'>
      <a
        className={`fa fa-eye${somethingHappening.includes('stats-on') ? '-slash' : ''}`}
        onClick={() => {
          if (somethingHappening.includes('stats-on')) {
            setSomethingHappening(somethingHappening.filter(s => s !== 'stats-on'))
          } else {
            setSomethingHappening([...somethingHappening, 'stats-on'])
          }
        }}
      />
      {/* }}>{somethingHappening.includes('stats-on') ? `🙈` : `👁`}</a> */}
      <div className='sort'>
        <span className='label'>Sort:</span>
        <select value={sortI} onChange={evt => setSortI(evt.target.value)}>
          {SORTS.map((f, i) => <option key={f.label} value={i}>{f.label}</option>)}
        </select>
      </div>
      <div>
        <ICStats>
          {/* <Link to='/save'>⚙️</Link> */}
        </ICStats>
      </div>
      {/* <div>
        Filter:
        <select value={filterI} onChange={evt => setFilterI(evt.target.value)}>
          {FILTERS.map((f, i) => <option key={f.label} value={i}>{f.label}</option>)}
        </select>
      </div> */}
    </div>
    {/* <div className='tab-wrap'>
      <div className='tabs'>
        <a className={classNames({ active: !lens })} onClick={() => setLens(false)}><DbAvatar /></a>
        <a className={classNames('lens', { active: lens })} onClick={() => setLens([ipfs.id])}>👁</a>
      </div>
      {tab}
    </div> */}
    <div className='bottom'>
      <IpfsInfo />
      {/* <UserInfo /> */}
    </div>
  </div>
}
export default Sidebar
