import classNames from 'classnames'
import { not } from 'ramda'
import { useState } from 'react'
import TagInput from './TagInput'
import { useHistory } from 'react-router-dom'

export const SearchInput = () => {
  const [open, setOpen] = useState(false)
  const history = useHistory()
  return (
    <div className={classNames('search-input', { open })}>
      <a onClick={() => {
        setOpen(not)
      }}  className='fa fa-magnifying-glass'></a>
      {open && <TagInput
        placeholder='search...'
        className='animate flip'
        preventUpload
        onChange={tag => {
          setOpen(false)
          if (!tag) {
            return
          }
          history.push(`/c/${encodeURIComponent(tag)}`)
        }}
      />}
    </div>
  )
}