import './App.scss'
import {
  useRecoilValue
} from 'recoil'
import ICWrap from './ICWrap'
import { useEffect, useState } from 'react'
import {
  Switch,
  Route,
  Link,
  useLocation
} from 'react-router-dom'
import See from './See'
import Sidebar from './Sidebar'
import EntryPoints from './EntryPoints'
import Save from './Save'
import * as st8 from './state'
import classNames from 'classnames'
import ViewingModeMessage from './ViewingModeMessage'
import UserInfo from './UserInfo'
import MultipleTags from './MultipleTags'
import UserPage from './UserPage'
import DbName from './DbName'
import IcLink from './IcLink'
import { contains } from 'ramda'
import Hamburger from './Hamburger'
import Collaborate from './Collaborate'
import RecentActivity from './RecentActivity'
import { SearchInput } from './SearchInput'

const getALogo = () => {
  const logos = [
    [['❄️', 'i'], ['🥶', 'c'], ['❄️', 'y']],
    [['👁', 'eye'], ['🌊', 'sea']],
    [['🙋🏾‍♀️', 'aye'], ['👍', 'sí']],
    [['✋🏽', 'I'], ['👀', 'see']],
    [['😮', 'oh,'], ['💡', 'I see!']],
    [['🧊', 'Iccee'], ['🍧', '!!!']],
    [['ℹ️', 'Intelligence'], ['©️', 'Collective']]
  ]
  return logos[Math.floor(Math.random() * logos.length)]
}

function AppWrap () {
  const [logo, setLogo] = useState(null)
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const isMine = useRecoilValue(st8.isMineFsState)
  const somethingIsHappening = useRecoilValue(st8.thingsHappeningState)
  const bc = useRecoilValue(st8.breadcrumbState)
  const location = useLocation()
  let link = <Link className='logo' to='/' />
  if (location.pathname === '/' && isOnline) {
    link = <a className='logo' href='/' />
  }
  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(window.navigator.onLine)
    }

    // Listen to the online status
    window.addEventListener('online', handleStatusChange)

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange)

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange)
      window.removeEventListener('offline', handleStatusChange)
    }
  }, [isOnline])
  // useEffect(() => {
  //   setLogo(getALogo())
  // }, [setLogo])
  // const logoIcon = logo && logo.map((arr, i) => <a href='/' key={i} title={arr[1]}>{arr[0]}</a>)
  const firstPathPiece = location.pathname.split('/')[1]

  return (
    <div className={classNames('App', `page-${firstPathPiece}`, { mine: isMine, 'not-mine': !isMine, 'something-is-happening': somethingIsHappening.length > 0 }, somethingIsHappening)}>
      {contains('perspectives', somethingIsHappening) && <UserPage />}
      <div className='main'>
        <div className='logo-wrap'>
          <h1>{link}</h1>
        </div>
        <nav>
          <Sidebar />
        </nav>
        <SearchInput />
        <Hamburger />
        <div className='location'>
          <IcLink />
          <div className='breadcrumb'>
            <div className='bc'>
              {bc.map(b => <Link key={b} to={`/c/${encodeURIComponent(b)}`}>{b}</Link>)}
            </div>
          </div>
        </div>
        <ICWrap />
        <MultipleTags />
        <Collaborate />
        <RecentActivity />
        <div className='content'>
          <Switch>
            <Route path='/c/*'>
              <See />
            </Route>
            <Route path='/save'>
              <Save />
            </Route>
            <Route path='/'>
              <EntryPoints />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default AppWrap
