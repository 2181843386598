import { filter, length, pipe, pluck, propEq, toPairs, uniq } from "ramda"
import { Link } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"
import DbAvatar from "./DbAvatar"
import Stats from "./Stats"
import { compressAddr, getDbId, icUrl } from "./lib/utils"
import { icfsConfigState, icFileServerUrlState, icConnectedState, icDataState, icSettingsState, icExportDataState } from "./state"

function ICStats ({ link, children }) {
  const icAddress = useRecoilValue(icConnectedState)
  const icSettings = useRecoilValue(icSettingsState)
  const icData = useRecoilValue(icDataState)
  const icFileServerUrl = useRecoilValue(icFileServerUrlState)
  const icfsConfig = useRecoilValue(icfsConfigState)
  const exportData = useSetRecoilState(icExportDataState)
  if (!icAddress) return null
  const users = pipe(
    pluck('dId'),
    uniq
  )(icData)
  let linksHtml
  if (icSettings.links && false) {
    const links = toPairs(icSettings.links)
    linksHtml = <div className='links'>
      {links.length > 1 && <a className='fa fa-rotate-right' onClick={() => exportData('refresh')} />}
      &nbsp;
      {links.length}
      <DbAvatar
        name={'hi'}
        size={12}
      />
      {/* {links.map(arr => <a key={`${arr[0]}-${arr[1].data}`} href={icUrl(arr[0])}>
        <DbAvatar
          name={getDbId(arr[0])}
          loading={arr[1].data === 'loading'}
          size={16}
        />
      </a>)} */}
    </div>
  }
  return <div className='ic-stats'>
    {linksHtml}
    <div className='stats-wrap'>
      <Stats data={{
        users: users.length,
        yes: pipe(filter(propEq('yesNo', '+')), length)(icData),
        no: pipe(filter(propEq('yesNo', '-')), length)(icData)
      }} />
    </div>
    {children}
  </div>
}
export default ICStats 
