import { prop, sort } from 'ramda'
import { useRecoilState, useRecoilValue } from 'recoil'
import * as st8 from './state'
import { Link } from 'react-router-dom'
import { formatDate } from './lib/utils'

export default function RecentActivity () {
  const data = useRecoilValue(st8.icDataState)
  const timestampedData = data.filter(prop('time'))

  return (
    <div className='recent-activity p-3 text-sm max-w-xs'>
      <h4 className='text-base'>Recent:</h4>
      <ul className='recent overflow-auto  max-h-40 md:max-h-96'>
        {sort((a, b) => a.time - b.time, timestampedData).slice(-40).reverse().map(t => <li key={t.from + t.to + t.time}>
          <div className='from-to'>
            <Link className='color-pop' to={`/c/${encodeURIComponent(t.to)}`}>{t.to}</Link>
            <span className='yes-no'>{t.yesNo === '-' ? ' x ' : ' • '}</span>
            <Link className='color-pop' to={`/c/${encodeURIComponent(t.from)}`}>{t.from}</Link>
            &nbsp;
            <div className='hint'>
              <span className='time'>{formatDate(t.time)}</span>
            </div>
          </div>
        </li>)}
      </ul>
    </div>
  )
}