import classNames from 'classnames'
import './Stats.scss'

function Stats ({ className, data }) {
  return <div className={classNames('stats', className)}>
    <span><span className='emoji'></span> {data.users}</span>
    <span><span className='yes-no'>•</span> {data.yes}</span>
    <span><span className='yes-no'>x</span> {data.no || '0'}</span>
    {data.percent && <span className='percent'>{data.percent}%</span>}
  </div>
}

export default Stats
