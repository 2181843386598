import { cleanDomain, compressAddr, createPerspective, fetchIcfsInfo, fetchJwt, getDomain, getSigner, getUrlQuery, httpDomain, patchIc, setIdb } from "./lib/utils"
import './Collaborate.scss'
import MyIcList from "./MyIcList"
import * as st8 from './state'
import { useSetRecoilState, useRecoilState } from "recoil"
import { append } from 'ramda'
import { useEffect, useState } from "react"

export default function Collaborate({  }) {
  const query = getUrlQuery()
  const setIcFileServerUser = useSetRecoilState(st8.icFileServerUserState)
  const [perspectives, setPerspectives] = useRecoilState(st8.perspectivesState)
  const setAddTag = useSetRecoilState(st8.icAddTagState)
  const [chooseOne, setChooseOne] = useState(false)
  const [log, setLog] = useState([])
  const [info, setInfo] = useState({})
  useEffect(() => {
    if (query.invite) {
      const go = async () => {
        const info = await fetchIcfsInfo(getDomain(query.invite))
        setInfo(info)
      }
      go()
    }
  }, [setInfo])
  if (!(query.collaborate || query.invite) || !query.auth) return null
  const isInvite = query.invite
  const collabPieces = cleanDomain(query.collaborate || query.invite).split('/')

  let show = <div className=''>
    <p className='text-2xl text-pop mb-3'>
      Should we create a new .ic for this {isInvite ? 'server' : 'collaboration'}?
    </p>
    <ul className='hint'>
      {log.map((l, i) => <li key={i}>{l}</li>)}
    </ul>
    <a onClick={async () => {
        const persp = createPerspective()

        const serverInfo = await fetchIcfsInfo(collabPieces[0])
        setLog(append(`✅ fetched ${serverInfo.name}`))
        const signer = await getSigner([persp], persp.address)
        setLog(append(`✅ created a new perspective ${compressAddr(persp.address)}`))
        const jwt = await fetchJwt(signer, serverInfo.url, persp.address)
        if (!jwt) {
          setLog(append(`☠️🪦 There was a problem creating your new .ic on ${collabPieces[0]}`))
          return
        }
        setLog(append(`✅ ${compressAddr(persp.address)} is all set on ${collabPieces[0]}`))
        persp.server = Object.assign(serverInfo, { jwt })
        setPerspectives(append(persp, perspectives))
        setIcFileServerUser(persp.address)

        setLog(append(`💃🏽🕺🏻 so let's do it...`))
        await makeCollab(persp)
    }} disabled={log.length > 0} className='btn'>yeah thanks</a>
    {!isInvite &&
      <p className='pt-6'>
        or you can <a className="underline" onClick={() => setChooseOne(true)}>use an existing .ic</a> 
      </p>
    }
  </div>
  const  makeCollab = async (p) => {
    if (isInvite) {
      const pServer = `${getDomain(p.server.url)}/${p.address}`
      const patchRes = await patchIc(query.auth, collabPieces[0], collabPieces[1], `.ic invites\n+${cleanDomain(pServer)}`)
    } else {
      const pServer = `${p.server.url}/${p.address}/index.ic`
      const patchRes = await patchIc(query.auth, collabPieces[0], collabPieces[1], `.ic collaborators\n+${pServer}`)
      setAddTag(append({
        from: query.collaborate,
        to: '.ic collaborators'
      }))
    }
    setTimeout(() => {
      window.location.href = '/' 
    }, 1000)
  }
  if (chooseOne) {
    show = (
      <>
        <h3 className='text-pop'>
          Choose an .ic to share with them.
        </h3>
        <MyIcList
          onPerspectiveChange={async p => {
            if (p.server) {
              const pServer = `${p.server.url}/${p.address}/index.ic`
              if (query.collaborate.includes(cleanDomain(pServer))) {
                alert('You are collaborating with yourself.')
                return
              }
              setIcFileServerUser(p.address)
              await makeCollab(p)
            } else if (!isInvite && confirm('This ic is not public. You can follow them, but they can\'t follow you. ok?')) {
              setIcFileServerUser(p.address)
              setTimeout(() => {
                setAddTag(append({
                  from: query.collaborate,
                  to: '.ic collaborators'
                }))
                setTimeout(() => {
                  window.location.href = '/' 
                }, 1000)
              }, 1500)
            }
          }}
        />
      </>
    )
  }

  let h2 = 'This is a collaboration link.'
  if (isInvite) {
    h2 = (
      <>
        <div className='pb-4'>You have been invited to:</div>
        {info.name ? <span>{info.name} <span className='hint'>({cleanDomain(getDomain(query.invite))})</span></span> : cleanDomain(getDomain(query.invite))}
        {info.description && <p className='text-base italic pt-1'>{info.description}</p>}
      </>
    ) 
  }


  return (
    <div className='collaborate z-10 top-0 flex-col fixed w-full h-screen bg-opacity-60 bg-pop flex justify-center items-center'>
      <div className="collaborate-inner bg-black max-w-prose h-2/3 p-6 relative">
        <h2 className="text-xl mb-6">{h2}</h2>
        {show}
        <a href="/" className='mt-6 underline absolute bottom-5 right-5'>{isInvite ? 'nah, i\'m not a joiner' : 'no collaboration for me thanks'}</a>
      </div>
    </div>
  )
}