import { useEffect, useRef, useState } from 'react'
import { ethers } from 'ethers'
import { append, path, filter, last, mergeLeft, omit, prop, uniqBy, uniq, pluck, match, pipe, toPairs, fromPairs } from 'ramda'
import {
  useRecoilState, useRecoilValue, useSetRecoilState
} from 'recoil'
import {
  icAddTagState,
  icConnectedState,
  icDataState,
  icExportDataState,
  icLinksDataState,
  icRawDataState,
  ipfsState,
  pinataJwtState,
  icFileServerUrlState,
  icFileServerUserState,
  icStorageState,
  icSettingsState,
  icFileServerState,
  perspectivesState,
  thingsHappeningState,
  icfsConfigState,
  isMineFsState
} from './state'
import * as st8 from './state'
import DBInfo from './DBInfo'
import { fetchIcfsInfo, getIdb, setIdb, createPerspective, copyToClipboard, getDbId, getStorage, pin, setStorage, isIcUrl, icUrl, icName, saveIc, patchIc, formatDate, labelForUser, cleanDomain, PUBLIC_IC_SERVERS } from './lib/utils'
import { useHistory } from 'react-router-dom'
// not messing with orbit db any more 2022 olw
// import IcOrbitDb from './lib/ic-js/src/IcOrbitDb'
const IcOrbitDb = {} 
const IC = require('ic-js')
const IPFS = require('ipfs')
const isIPFS = require('is-ipfs')
const search = new URLSearchParams(document.location.search.substring(1))

const isLinkedIc = pth => {
  return IcOrbitDb.isValidAddress(pth) || isIPFS.path(pth) || isIcUrl(pth)
}


function ICWrap () {
  const ipfs = useRef()
  const ic = useRef()
  const icLinks = useRef()
  const exportTout = useRef()
  const [lastIcSave, setLastIcSave] = useRecoilState(st8.lastIcSaveState)
  const setData = useSetRecoilState(icRawDataState)
  const icData = useRecoilValue(icDataState)
  const [addTags, setAddTags] = useRecoilState(icAddTagState)
  const [icAddress, setIcConnected] = useRecoilState(icConnectedState)
  const [ipfsInternal, setIpfs] = useRecoilState(ipfsState)
  const [icStorage, setIcStorage] = useRecoilState(icStorageState)
  const [icLinksData, setIcLinksData] = useRecoilState(icLinksDataState)
  const [exportDataKey, exportData] = useRecoilState(icExportDataState)
  const [icFileServer, setIcFileServer] = useRecoilState(icFileServerState)
  const pinataJwt = useRecoilValue(pinataJwtState)
  const [icFileServerUrl, _setIcFileServerUrl] = useRecoilState(icFileServerUrlState)
  const icUrlInternal = useRef(icFileServerUrl)
  const [icFileServerUser, setIcFileServerUser] = useRecoilState(icFileServerUserState)
  const [perspectives, setPerspectives] = useRecoilState(perspectivesState)
  const [somethingHappening, setSomethingHappening] = useRecoilState(thingsHappeningState)
  const [knownIcServers, setKnownIcServers] = useRecoilState(st8.knownIcServersState)
  const icSettings = useRecoilValue(icSettingsState)
  const setIcfsConfig = useSetRecoilState(icfsConfigState)
  const setSelectedTags = useSetRecoilState(st8.selectedTagsState)
  const isMine = useRecoilValue(isMineFsState)
  const history = useHistory()

  const isUrl = icStorage === 'ic-file-server'

  const getIcUrl = () => {
    return icUrlInternal.current
  }
  const setIcFileServerUrl = url => {
    _setIcFileServerUrl(url)
    icUrlInternal.current = url
  }

  const createIC = async (ipfs, addr, name) => {
    if (isIcUrl(addr)) {
      const ic = new IC()
      const pastIcs = (await getStorage('pastIcs')) || []
      if (!pastIcs.includes(addr)) {
        setStorage('pastIcs', append(addr, pastIcs))
      }
      // this is my IC so we dont want to treat it as an external IC
      const regex = new RegExp(`${getIcUrl().replace(/\/ic$/, '')}/${icFileServerUser}/`)
      if (regex.test(addr)) {
        let str = await fetch(addr).then(res => res.text())
        const firstLine = str.split('\n')[0]
        if (!firstLine.startsWith('_')) {
          str = `_\n${str}`
        }
        // add this address to _imported so that if it occurs
        // in another we wont double add it
        ic._importedIcs.push(addr)
        await ic.import(str.replace(/^_.*\n/, `_${ic.id}\n`))
        // an external IC
      } else {
        await ic.import(addr)
      }
      setLastIcSave(Date.now())
      return ic
    } else {
      const args = {
        ipfs,
        name
      }
      if (addr) {
        args.orbitdb = { db: addr }
      }
      return IcOrbitDb.create(args)
    }
  }

  const doIpfs = async () => {
    icLinks.current = {}
    ipfs.current = await IPFS.create({
      repo: 'test',
      start: true,
      preload: {
        enabled: false
      },
      EXPERIMENTAL: {
        pubsub: true
      },
      config: {
        relay: {
          enabled: true,
          hop: {
            enabled: true
          }
        },
        Addresses: {
          Swarm: [
            // Use IPFS dev signal server
            // '/dns4/star-signal.cloud.ipfs.team/wss/p2p-webrtc-star',
            // '/dns4/ws-star.discovery.libp2p.io/tcp/443/wss/p2p-websocket-star',
            // Use IPFS dev webrtc signal server
            '/dns4/radiant-woodland-43627.herokuapp.com/tcp/443/wss/p2p-webrtc-star/'
            // '/dns4/wrtc-star1.par.dwebops.pub/tcp/443/wss/p2p-webrtc-star/',
            // '/dns4/wrtc-star2.sjc.dwebops.pub/tcp/443/wss/p2p-webrtc-star/',
            // '/dns4/webrtc-star.discovery.libp2p.io/tcp/443/wss/p2p-webrtc-star/'
            // Use local signal server
            // '/ip4/0.0.0.0/tcp/9090/wss/p2p-webrtc-star',
          ]
        }
      }
    })
    window.ipfs = ipfs.current
    console.log('IPFS node is ready')
    const { id } = await ipfs.current.id()
    setIpfs(mergeLeft({ id }))
    // connect to our peer
    ipfs.current.swarm.connect('/dns4/ipfs.aye.si/tcp/4002/wss/p2p/12D3KooWPUj2txDuBWr6xGEn9AaYhdWWa2ABFfUYVZoVKotcfwJZ')

    ipfs.current.libp2p.connectionManager.on('peer:connect', async function (peer) {
      // console.log(peer.remotePeer.toString());
      const peers = await ipfs.current.swarm.peers()
      setIpfs(mergeLeft({ peers }))
    })
  }

  // init
  useEffect(() => {
    const go = async () => {
      const knownServers = await getIdb('knownIcServers')
      setKnownIcServers(knownServers || {})
    }
    go()
  }, [setKnownIcServers])

  // perspective chane
  useEffect(() => {
    async function go () {
      if (ipfs.current) return
      // create a perspective if it's the first time
      if(perspectives.length < 1) {
        const persp = createPerspective()
        perspectives.push(persp)
        setPerspectives(perspectives)
        setIcFileServerUser(persp.address)
      }
      if (search.get('ic')) {
        connectIC(search.get('ic'))
      }
    }
    go()
  }, [setData, perspectives, setPerspectives])

  const localOrRemoteIcConnect = async () => {
    const persp = perspectives.find(p => p.address === icFileServerUser)
    const icStr = await getIdb(icFileServerUser + '-ic')
    if (persp && persp.server) {
      setIcFileServerUrl(persp.server.url)
      await connectIC(`${persp.server.url}/${persp.address}/index.ic`)
      // is this the first time?
      if (icStr) {
        const data = icStr.replace(/^_.*\n/, ``)
        exportData({
          key: 'patch',
          data
        })
        await setIdb(icFileServerUser + '-ic', null)
        ic.current.import(data)
      }
    } else {
      setIcFileServerUrl(false)
      connectIC(icFileServerUser)
      if (icStr) {
        ic.current.import(icStr.replace(/^_.*\n/, `_${ic.current.id}\n`).replace(/(,undefined)+\n/g, '\n'))
      }
    }
  }

  // a user is chosen
  useEffect(() => {
    if (!icFileServerUser) return
    localOrRemoteIcConnect()
  }, [icFileServerUser])


  // create tags
  useEffect(() => {
    if (addTags.length === 0) return
    const tag = last(addTags)
    if (typeof tag === 'string') {
      ic.current.import(tag)
    } else if(typeof tag === 'object') {
      ic.current.tag(tag.to, tag.from, tag.yesNo)
    }
    setAddTags(addTags.slice(0, -1))
    if (isMine) {
      clearTimeout(exportTout.current)
      exportTout.current = setTimeout(() => {
        exportData('mine')
      }, 500)
    }
  }, [addTags, isMine])


  // linked dbs
  useEffect(() => {
    if (!icAddress || !ipfsInternal.id || isUrl) return

    // orbitdb stuff
    const dbId = getDbId(icAddress)
    const newLinkAddrs = icData
      .filter(t => t.to === dbId && isLinkedIc(t.from) && t.dId === ipfsInternal.id && t.yesNo === '+')
      .filter(t => !icLinks.current[t.from])
      .map(prop('from'))
    const linkAddrsToRemove = icData
      .filter(t => t.to === dbId && isLinkedIc(t.from) && t.dId === ipfsInternal.id && t.yesNo === '-')
      .filter(t => icLinks.current[t.from])
      .map(prop('from'))
    newLinkAddrs.forEach(async addr => {
      icLinks.current[addr] = true // otherwise you get an endless loop
      setIcLinksData(st8 => {
        const obj = {}
        obj[addr] = 'loading'
        return mergeLeft(obj, st8)
      })
      // load an external ic
      if (IcOrbitDb.isValidAddress(addr)) {
        icLinks.current[addr] = await createIC(ipfs.current, addr)
        // create a local db for this ic import file
      } else if (isIPFS.path(addr)) {
        icLinks.current[addr] = await createIC(ipfs.current)
        await icLinks.current[addr].import(addr)
      } else if (isIcUrl(addr)) {
        icLinks.current[addr] = await createIC(ipfs.current, addr)
      }
      icLinks.current[addr].on('data', data => {
        setIcLinksData(st8 => {
          const obj = {}
          obj[addr] = data
          return mergeLeft(obj, st8)
        })
      })
      icLinks.current[addr].load()
    })
    linkAddrsToRemove.forEach(addr => {
      delete icLinks.current[addr]
      setIcLinksData(st8 => {
        return omit([addr], st8)
      })
    })
  }, [icData, icAddress, ipfsInternal])

  // export data
  useEffect(() => {
    if (!exportDataKey) return
    const go = async () => {
      const key = typeof exportDataKey === 'string' ? exportDataKey : exportDataKey.key
      setSomethingHappening(append('export'))
      const data = path(['data'], exportDataKey)
      let fileName = icName(path(['id'], icSettings))
      if (data && data.fileName) {
        fileName = data.fileName
      }
      const getSigner = async () => {
          const persp = perspectives.find(p => p.address === icFileServerUser)
          let signer 
          if (persp.server && persp.server.jwt) {
            return persp.server.jwt
          } else if (persp) {
            signer = new ethers.Wallet(persp.private)
          } else if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            await provider.send("eth_requestAccounts", []);
            signer = provider.getSigner()
            const addr = await signer.getAddress()
            if (addr !== icFileServerUser) {
              signer = null
            }
          }
          return signer
      }
      if (key === 'mine') {
        let icExportModify = filter(t => t.dId === ipfsInternal.id) 
        if (isUrl) {
          const initialIc = icLinks.current || icAddress
          if (lastIcSave) {
            icExportModify = pipe(
              icExportModify,
              filter(t => t.time > lastIcSave)
            ) 
          }
          let icStr = (IC.isIcUrl(initialIc) && path(['prependAddr'], data) ? `${initialIc}\n` : '') + ic.current.export(icExportModify)
          const signer = await getSigner()
          if (signer) {
            let res = {}
            if (lastIcSave) {
              icStr = icStr.replace(/^_.*\n/g, '')
              if (icStr) {
                res = await patchIc(signer, icFileServerUrl, icFileServerUser, icStr)
              }
              setLastIcSave(Date.now())
            } else {
              res = await saveIc(signer, icFileServerUrl, icFileServerUser, icStr)
            }
            if (res.files) {
              setIcFileServer(mergeLeft({ lastSave: res.files[0] }))
            }
          }
        } else if (icStorage === 'local') {
          await setIdb(icFileServerUser + '-ic', ic.current.export(icExportModify))
        }
      } else if (key === 'patch') {
        const signer = await getSigner()
        if (signer) {
          const res = await patchIc(signer, icFileServerUrl, icFileServerUser, data)
          console.log(res)
        }
      } else if (key === 'seed') {
        let seeds = data 
        const opts = {}
        if (!Array.isArray(data)) {
          seeds = data.seeds
          if (data.depth) {
            opts.depth = data.depth
          }
        }
        const seededIc = ic.current.seed(seeds, opts)
        const persp = createPerspective()
        persp.label = `Seed of ${seeds.join(', ')} ${formatDate(new Date())}`
        await setIdb(persp.address + '-ic', seededIc.export())
        setPerspectives(append(persp, perspectives))
        setIcFileServerUser(persp.address)
        setSelectedTags([])
        history.push('/')

      } else if (key === 'all') {
        const cid = await ic.current.exportToIpfs({
          add: content => {
            pin(content, fileName)
          }
        })
        copyToClipboard('http://ipfs.aye.si/ipfs/' + cid.path)
      } else if (key === 'all-file') {
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        const blob = new Blob([ic.current.export()], { type: 'plain/text' })
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.target = '_blank'
        a.download = labelForUser(icFileServerUser, perspectives).replace(/[^a-zA-Z0-9]/g, '-') + '.ic'
        a.click()
        window.URL.revokeObjectURL(url)
      } else if (key === 'refresh') {
        connectIC(icSettings.address)
      }
      exportData(false)
      setSomethingHappening(filter(k => k !== 'export'))
    }
    go()
  }, [exportDataKey])

  // // // NSYNC // // //

  // keep storage insync
  useEffect(() => {
    if (perspectives) {
      setStorage('perspectivesState', perspectives)
    }
  }, [perspectives])

  // pinata jwt
  useEffect(() => {
    setStorage('pinataJwt', pinataJwt)
  }, [pinataJwt])

  // ic file server url
  useEffect(() => {
    setStorage('icFileServerUrl', icFileServerUrl)
  }, [icFileServerUrl])

  // ic file server username
  useEffect(() => {
    setStorage('icFileServerUser', icFileServerUser)
  }, [icFileServerUser])

  // fetch icfs config
  useEffect(() => {
    setIcfsConfig(mergeLeft({ name: null }))
    if (!icAddress) return
    const go = async () => {
      const matches = icAddress.match(/https?:\/\/[^\/]+\//)
      if (matches && matches[0]) {
        const ic = new IC({ importDepth: 1 })
        await ic.import(`${matches[0]}index.ic`)
        const nameTags = ic.all().filter(t => t.to === 'name').map(t => t.from)
        if(nameTags) {
          const icfsNameTag = ic.all().find(t => nameTags.includes(t.from) && t.to === 'icfs')
          if(icfsNameTag) {
            setIcfsConfig(mergeLeft({ name: icfsNameTag.from }))
          }
        }
      }
    }
    go()
  }, [icAddress])

  // track linked dbs to find icfs' 
  useEffect(() => {
    if (!knownIcServers) return
    const go = async () => {
      const checkedIcfsServers = Object.keys(knownIcServers) 
      const icfses = Object.keys(icLinksData).concat(PUBLIC_IC_SERVERS.map(s => `https://${s}/`))
      const possibleIcfsServers = uniq(icfses
        .map(k => k.match(/https?:\/\/[^\/]+\//))
        .filter(Boolean)
        .map(m => m[0]))
        .filter(url => !checkedIcfsServers.includes(cleanDomain(url)))
      const newKnownServers = { ...knownIcServers }
      possibleIcfsServers.forEach(url => {
        newKnownServers[cleanDomain(url)] = { status: 'loading' }
      })
      if (possibleIcfsServers.length) {
        setKnownIcServers(newKnownServers)
        await Promise.all(possibleIcfsServers.map(async url => {
          const info = await fetchIcfsInfo(url)
          const ret = {}
          let newKnownServers 
          // it is an icfs
          if (info.url) {
            ret[cleanDomain(url)] = { status: 'ok', ...info }
            // not
          } else {
            ret[cleanDomain(url)] = { status: 'no', ...info }
          }
          newKnownServers = Object.assign({}, knownIcServers, ret)
          setKnownIcServers(newKnownServers)
          await setIdb('knownIcServers', pipe(
            toPairs,
            filter(([k, v]) => v.status !== 'loading'),
            fromPairs
          )(newKnownServers))
        }))
      }
    }
    go()
  }, [icLinksData, knownIcServers])


  // // // bye bye bye // // //

  const connectIC = async (addr, name) => {
    // url
    if (isIcUrl(addr)) {
      try {
        ic.current = await createIC(ipfs.current, addr, name)
        const dbs = getStorage('dbs') || []
        dbs.push({ address: addr })
        setStorage('dbs', uniqBy(prop('address'), dbs))
        ic.current.on('data', setData)
        setIcStorage('ic-file-server')
        setData(ic.current.all())
        setIpfs(mergeLeft({ id: ic.current.id, mockIpfs: true }))
        setIcConnected(addr)
        const obj = {}
        ic.current.externalIcs().forEach(ic => {
          obj[ic] = []
        })
        setIcLinksData(obj)
        return
      } catch (e) {
        alert('Cannot connect to ' + addr + ' you can work offline for now')
        console.log(e)
      }
    }
    // otherwise create in memory IC
    setData([])
    ic.current = new IC()
    ic.current.on('data', setData)
    setIcStorage('local')
    setIpfs(mergeLeft({ id: ic.current.id, mockIpfs: true }))
    setIcConnected(addr)
    // IPFS
    // } else {
    //   await doIpfs()
    //   setIcStorage('ipfs')
    //   ic.current = await createIC(ipfs.current, addr, name)
    //   ic.current.on('data', setData)
    //   await ic.current.load()
    //   setIcConnected(ic.current.db().address.toString())
    //   // save it for later
    //   const dbs = getStorage('dbs') || []
    //   dbs.push({ address: ic.current.db().address.toString() })
    //   setStorage('dbs', uniqBy(prop('address'), dbs))
  
    //   // first time
    //   if (!addr) {
    //     console.log('adding to public db log')
    //     const publicDb = await ic.current.orbitdb.open('/orbitdb/zdpuAxw9AWTHdsnTgRr6GHR85kT4ZLMHaASrcqpvv8pVsuphD/aye.si')
    //     const hash = await publicDb.add({
    //       address: ic.current.db().address.toString(),
    //       created: Date.now()
    //     })
    //     console.log(hash)
    //   }
    // }
  }
  return null
  // return (
  //   <DBInfo connectIC={connectIC} />
  // )
}

export default ICWrap
