import { useRecoilValue } from 'recoil'
import { ipfsState, icConnectedState } from './state'

function IpfsInfo () {
  const ipfs = useRecoilValue(ipfsState)
  const icConnected = useRecoilValue(icConnectedState)
  if (ipfs.mockIpfs || !icConnected) return null
  return <div className='ipfs-peers'>
    {ipfs.peers ? ipfs.peers.length : '0'}
  </div>
}
export default IpfsInfo
