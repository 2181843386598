import Avatar from 'boring-avatars'
import './DbAvatar.scss'
import { useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { getDbSettings } from './lib/utils'
import { icSettingsState } from './state'

function DbAvatar ({ name, loading, size = 23 }) {
  const _int = useRef()
  const [rand, setRand] = useState(23)
  const icSettings = useRecoilValue(icSettingsState)
  if (!name) {
    name = icSettings.id
  }
  const dbSettings = getDbSettings(icSettings, name)
  useEffect(() => {
    const cleanup = () => {
      if (_int.current) {
        clearInterval(_int.current)
      }
    }
    if (!loading) {
      cleanup()
      return cleanup
    }
    if (!_int.current) {
      _int.current = setInterval(() => {
        setRand(() => Math.random())
      }, 100)
    }
    return cleanup
  }, [loading])
  if (dbSettings.image) {
    return <div className='db-avatar' style={{ backgroundImage: `url(${dbSettings.image})` }} />
  }
  return <Avatar
    size={size}
    name={loading ? rand.toString() : name}
    variant='ring'
    colors={['#2b2726', '#0A516D', '#018790', '#7dad93', '#bacca4']}
  />
}

export default DbAvatar
