import { useRecoilState, useSetRecoilState } from 'recoil'
import * as st8 from './state'
import './MultipleTags.scss'
import { ReactComponent as X } from './assets/x.svg'
import { Link, useRouteMatch } from 'react-router-dom'
import { append } from 'ramda'
import { useEffect, useState } from 'react'
import TagInput from './TagInput'

function BtnThenSelect ({ options, selectLabel, btnLabel, onSelect }) {
  const [clicked, setClicked] = useState(false)
  if (clicked) {
    return (
      <span className='btn-then-select'>
        <select onChange={(evt) => {
          if (evt.target.value !== '' && onSelect) onSelect(evt.target.value)
        }}>
          <option value=''>{selectLabel}</option>
          {options.map(option => (
            <option key={option} value={option} >{option}</option>
          ))}
        </select>
      </span>
    )
  }
  return (
    <a onClick={() => setClicked(true)}>{btnLabel}</a>
  )
}

export default function MultipleTags () {
  const match = useRouteMatch('/c/*')
  const { params } = match || {}
  const [selectedTags, setSelectedTags] = useRecoilState(st8.selectedTagsState)
  const [above, setAbove] = useState(false)
  const [below, setBelow] = useState(false)
  const [open, setOpen] = useState(false)
  const setAddTag = useSetRecoilState(st8.icAddTagState)
  const exportData = useSetRecoilState(st8.icExportDataState)
  useEffect(() => {
    if (selectedTags.length === 0) {
      setOpen(false)
    }
  }, [selectedTags, setOpen])
  const tagParams = (params && params[0] && params[0].split('/').map(decodeURIComponent).sort((a, b) => a.localeCompare(b))) || []
  if (selectedTags.length < 2 && !(tagParams.length === 1 && tagParams[0] === selectedTags[0])) return null
  const close = <a className='close' onClick={() => setSelectedTags([])} >x</a>
  if (!open) {
    return (
      <div className='multiple-tags animate drop'>
        {close}
        <a onClick={() => setOpen(true)} className='inline-block p-3 mr-5'><i className='fa fa-toolbox' /> open toolbar</a>
      </div>
    )
  }
  let btns = [
    <BtnThenSelect
      key='seed'
      btnLabel={`Seed from "${selectedTags.join('", "')}"`}
      selectLabel='Choose seed depth'
      options={[-1, 1, 2, 3, 4]}
      onSelect={depth => {
        exportData({
          key: 'seed',
          data: {
            seeds: selectedTags,
            depth: parseInt(depth)
          }
        })
      }}
    />
  ]
  if (selectedTags.length > 1) {
    btns.push(
      <Link key='goto' to={`/c/${selectedTags.slice(0).sort((a, b) => a.localeCompare(b)).join('/')}`}>go to</Link>
    )
    if (tagParams.length) {
        btns.push(
          <Link key='add' to={`/c/${tagParams.concat(selectedTags).sort((a, b) => a.localeCompare(b)).join('/')}`}>add to filter</Link>
        )
        btns.push(
          <a key='yes' onClick={() => {
            tagParams.forEach(to => {
              selectedTags.forEach(from => {
                setAddTag(append({
                  to,
                  from,
                  yesNo: '+'
                }))
              })
            })
            setSelectedTags([])
          }} className='yes-icon'><i /> "{tagParams.join('", "')}" </a>
        )
        btns.push(
          <a key='x' onClick={() => {
            tagParams.forEach(to => {
              selectedTags.forEach(from => {
                setAddTag(append({
                  to,
                  from,
                  yesNo: '-'
                }))
              })
            })
            setSelectedTags([])
          }}><X /> "{tagParams.join('", "')}" </a>
        )
        btns.push(
          <a key='above' onClick={() => {
            setAbove(true)
          }}>put something above these</a>
        )
        btns.push(
          <a key='below' onClick={() => {
            setBelow(true)
          }}>tag all of these</a>
        )
    }
  }
  if (below) {
    btns = [
      <a key='below-selected' className='active' onClick={() => {
        setBelow(false)
      }}>put something below these</a>,
      <div key='what'>
        <TagInput 
          placeholder={'what?'}
          onChange={from => {
            selectedTags.forEach(to => {
              setAddTag(append({
                from,
                to
              }))
            })
            setBelow(false)
            setSelectedTags([])
          }}
        />
      </div>
    ]
  }
  if (above) {
    btns = [
      <a key='above-selected' className='active' onClick={() => {
        setAbove(false)
      }}>put something above these</a>,
      <div key='what'>
        <TagInput 
          placeholder={'what?'}
          onChange={to => {
            selectedTags.forEach(from => {
              setAddTag(append({
                from,
                to
              }))
            })
            setAbove(false)
            setSelectedTags([])
          }}
        />
      </div>
    ]
  }
  return (
    <div className='multiple-tags animate drop open'>
      {close}
      <ul className='selected-tags'>
        {selectedTags.map((tag, i) => (<li key={tag}>{tag}</li>))}
      </ul>
      <div className='btns'>
        {btns}
      </div>
    </div>
  )
}