import { append, last, split, pipe } from 'ramda'
import { useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { icUrl, icName, labelForUser, copyToClipboard } from './lib/utils'
import { perspectivesState, ipfsState, icAddTagState, isMineFsState, icFileServerState, icFileServerUrlState, icFileServerUserState, icSettingsState, icExportDataState } from './state'
const IC = require('ic-js')
import './Save.scss'

function Save () {
  const icSettings = useRecoilValue(icSettingsState)
  const icFileServerUrl = useRecoilValue(icFileServerUrlState)
  const icFileServerUser = useRecoilValue(icFileServerUserState)
  const ipfs = useRecoilValue(ipfsState)
  const icFileServer = useRecoilValue(icFileServerState)
  const setAddTag = useSetRecoilState(icAddTagState)
  const exportData = useSetRecoilState(icExportDataState)
  const [name, setName] = useState('')
  const [followed, setFollowed] = useState(false)
  const [importStr, setImportStr] = useState('')
  const isMine = useRecoilValue(isMineFsState)
  const perspectives = useRecoilValue(perspectivesState)
  let show
  // not mine
  // let show = <div className='section'>
  //   <p>
  //     You are viewing someone else's IC:<br/><span className='hint'>{icSettings.address}</span>
  //   </p>
  //   {followed ? 
  //     <p>Fun fact: If you want to follow another IC, you can add the url to their .ic file anywhere in yours. All we just did was create a new thot called "linked ICs" and we added their url to it. If you ever want to stop following it, just set that connection to "no".</p> :     
  //     <p>
  //     <a onClick={() => {
  //       exportData({
  //         key: 'patch',
  //         data: `linked ICs\n+${icSettings.address}`
  //       })
  //       setFollowed(true)
  //     }}>Click here</a> to follow their IC with "{labelForUser(icFileServerUser, perspectives)}" 
  //   </p>}
  // </div>
  const links = Object.keys(icSettings.links || {}).filter(url => url !== icSettings.address).length 
  show = (
    <>
      <div className='section'>
        <p>
          This ic belongs to {isMine ? ' you ' : ' someone else'}. Others can view it <a target='_blank' href={icUrl(icFileServerUrl + '/' + icFileServerUser + '/index.ic')}>here</a> (<a onClick={() => copyToClipboard(icUrl(icFileServerUrl + '/' + icFileServerUser + '/index.ic'))}>copy</a>)
        </p>
        <p>
          Jfyi, your actual .ic file is <a href={icFileServerUrl + '/' + icFileServerUser + '/index.ic'} target='_blank'>here</a> (<a onClick={() => copyToClipboard(icFileServerUrl + '/' + icFileServerUser + '/index.ic')}>copy</a>)
        </p>
      </div>
      <div className='section'>
        <h3>Import</h3>
        <p className='hint'>Paste an .ic string and we'll add it. The first perspective is assumed to be yours.</p>
        <textarea onChange={e => setImportStr(e.target.value)} value={importStr} />
        <button onClick={() => {
          // add my id to the import string
          const str = `_${ipfs.id}\n` + importStr.replace(/^_.*\n/, '')
          setAddTag(append(str))
          setImportStr('')
        }} className='btn btn-primary'>Import</button>
      </div>
      </>
  )
  if (IC.isCid(icName(icSettings.address || '').replace('.ic', ''))) {
    show = <div className='section'>
    <p>
      This is a static ic. You'll need to give it a name in order to save it.
      <input type='text' value={name} onChange={e => setName(e.target.value.replace('.ic', ''))} />
      {name && <button onClick={() => exportData({
        key: 'mine',
        data: {
          fileName: name + '.ic'
        }
      })}>Save</button>}
    </p>
    </div>
  }
  if (!IC.isIcUrl(icSettings.address || '')) {
    show = <div className='section'>
      <p>
        Your IC isn't saved anywhere yet.
      </p>
      <p>
        <a onClick={() => exportData('mine')}>Let's take care of that.</a>
      </p>
    </div>
  }
  if (!icSettings.address) {
    show = null
  }

  return (
    <div className="save centered">
      {show}
      {icFileServer.lastSave && <div className='section'>
        <h3>
          It's saved!
        </h3>
        <p>
          <h4>Dynamic URL</h4>
          <a href={icUrl(icFileServerUrl + icFileServer.lastSave.dynamic)}>This link</a> will always show your latest version of "{icName(icFileServer.lastSave.dynamic)}". This is a good link to share if you want to collaborate with others.<br/>
          <input onFocus={evt => evt.target.select()} value={icFileServerUrl + icFileServer.lastSave.dynamic} />
        </p>
        <p>
          <h4>Static URL</h4>
          <a target='_blank' href={icUrl(icFileServerUrl + '/' + icFileServer.lastSave.static)}>This link</a> will never change. It will always show this version ({icName(icFileServer.lastSave.static)}). <br/>
          <input onFocus={evt => evt.target.select()} value={icFileServerUrl + icFileServer.lastSave.static} />
        </p>
      </div>}
    </div>
  )

}
export default Save
