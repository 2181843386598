import { replace, append, find, last, path, propEq, split, pipe } from 'ramda'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { icExportDataState, icFileServerUrlState, icFileServerUserState, icAddTagState, icConnectedState, icSettingsState } from './state'
import TagInput from './TagInput'
import './DBInfo.scss'
import { getDbId, getStorage, icUrl, isIcUrl } from './lib/utils'
import { useEffect, useState } from 'react'
import { reverse } from 'ramda'
const search = new URLSearchParams(document.location.search.substring(1))

function DBInfo ({ connectIC }) {
  const icAddress = useRecoilValue(icConnectedState)
  const icSetting = useRecoilValue(icSettingsState)
  const setAddTag = useSetRecoilState(icAddTagState)
  const icFileServerUser = useRecoilValue(icFileServerUserState)
  const icFileServerUrl = useRecoilValue(icFileServerUrlState)
  const exportData = useSetRecoilState(icExportDataState)
  const [showTemplate, setShowTemplate] = useState(false)
  const [serverFound, setServerFound] = useState(false)

  // init & changing servers
  useEffect(() => {
    if(!icFileServerUser || !icFileServerUrl || search.get('ic')) return
    async function go () {
      try {
        const url = `${icFileServerUrl}/${icFileServerUser}/index.ic`
        setServerFound('loading')
        const res = await fetch(url)
        // if exists, load it
        if (res.status === 200) {
          setServerFound(url)
          connectIC(url)
        } else {
          setServerFound(false)
        }
      } catch (e) {
        console.log(e)
        setServerFound(false)
      }
    }
    go()
  }, [icFileServerUrl, icFileServerUser, setServerFound])

  if (!icAddress) {
    let more = null
    const dbs = getStorage('dbs')
    // if (dbs) {
    //   more = [
    //     <p className='more' key='create-one'>It looks like you've been here before.<br/> Here are the ICs you've accessed in the past</p>, 
    //     ...reverse(dbs).map(db => <a key={db.address} href={`${icUrl(db.address)}`}>{getDbId(db.address)}</a>)
    //   ]
    // }
    let create = <>
      <li>
        <a onClick={() => setShowTemplate(true)} >Start HERE</a>
      </li>
    </>
    if (showTemplate) {
      create = <>
      </>
      more = null
    }
    let startBtn = (
      <button className='btn btn-primary start' onClick={() => {
        const theName = `${icFileServerUrl}/${icFileServerUser}/index.ic`
        connectIC(theName)
      }}>Start A New IC</button>
    )
    if (serverFound === 'loading') {
      startBtn = (
        <p>
          checking to see if you have an .ic here
        </p>
      )
    } else if (serverFound) {
      startBtn = (
        <button className='btn btn-primary start' onClick={() => {
          connectIC(serverFound)
        }}>Connect to your IC</button>
      )
    }
    return <div className='create-db'>
      <h1>Welcome to IC!</h1>
      {startBtn}
      <p>or</p>
      <p>Paste an .ic URL explore another.</p>
      <div className='flex'>
        <a className='plus open' onClick={() => setShowTemplate(false)}>+</a>
        <TagInput options={[]} onChange={theName => {
          if (isIcUrl(theName)) {
            window.location.search = `?ic=${theName}`
          } else {
            setIcFileServerUser(theName)
            theName = theName.trim().replace(/\.ic$/, '') + '.ic'
            connectIC(theName, theName)
          }
        }} placeholder='Here!' />
      </div>      
      {more}
    </div>
  }
  return null
}

export default DBInfo
