import { useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { icUrl, icName, labelForUser, copyToClipboard } from './lib/utils'
import { perspectivesState, ipfsState, icAddTagState, isMineFsState, icFileServerState, icFileServerUrlState, icFileServerUserState, icSettingsState, icExportDataState } from './state'
import * as st8 from './state'

function ViewingModeMessage() {
  const isMine = useRecoilValue(isMineFsState)
  const icSettings = useRecoilValue(icSettingsState)
  const icFileServerUser = useRecoilValue(icFileServerUserState)
  const icFileServerUrl = useRecoilValue(st8.icFileServerUrlState)
  const [followed, setFollowed] = useState(false)
  const perspectives = useRecoilValue(perspectivesState)
  const exportData = useSetRecoilState(icExportDataState)
  const latestData = useRecoilValue(st8.icLatestDataState)

  if (!icSettings.address || isMine) return null
  return (
    <div className='viewing-mode-message inverse'>
      <div className='section'>
        <p>
          You are viewing someone else's IC:<br/><span className='hint'>{icSettings.address}</span>
        </p>
        {followed ? 
          <>
            {/* <p>Fun fact: If you want to follow another IC, you can add the url to their .ic file anywhere within yours. All we just did was create a new thot called "linked ICs" and we added their .ic file to it. If you ever want to stop following it, just set that connection to "no".</p> */}
            <p>Added! <a href="/">Go check it out</a></p>
          </> :     
          <p>
            💾 <span className='hint'>{labelForUser(icFileServerUser, perspectives)}</span>
            <ul>
              {latestData.length > 0 && <li>
                <a className='btn' onClick={() => {
                  exportData('mine')
                }}>only {latestData.length} new tags</a>
               </li>}
              <li>
                <a className='btn' onClick={() => {
                  exportData({
                    key: 'patch',
                    data: `linked ICs\n+${icSettings.address}`
                  })
                  setFollowed(true)
                }}>all as a linked IC</a>
              </li>
            </ul>
        </p>}
      </div>
    </div>
  )
}
export default ViewingModeMessage