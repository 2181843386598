import { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import * as st8 from './state'
import { append, contains } from "ramda"
import './Hamburger.scss'
import { Sling as Hamburgerz } from 'hamburger-react'

function Hamburger ({ }) {
  const [somethingHappening, setSomethingHappening] = useRecoilState(st8.thingsHappeningState)

  return (
    <span className='humburger'>
      <Hamburgerz 
        size={20}
        toggled={contains('perspectives', somethingHappening)}
        toggle={() => {
          if (contains('perspectives', somethingHappening)) {
            setSomethingHappening(somethingHappening.filter(a => a !== 'perspectives'))
          } else {
            setSomethingHappening(append('perspectives')) 
          }
        }} 
      />
    </span> 
  )
}
export default Hamburger 
