import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import './Uploader.scss'
import classNames from 'classnames'
import { pin } from './lib/utils'
import { useRecoilState } from 'recoil'
import * as st8 from './state'
import { append, filter } from 'ramda'

export default function Uploader ({ onUpload, single, children }) {
  const [uploading, setUploading] = useState(false)
  const [somethingHappening, setSomethingHappening] = useRecoilState(st8.thingsHappeningState)
  const onDrop = useCallback(async acceptedFiles => {
    setUploading(true)
    setSomethingHappening(append('uploading'))
    const file = acceptedFiles[0]
    const cid = await pin(file)
    if (onUpload) onUpload({ cid, file })
    setUploading(false)
    setSomethingHappening(filter(k => k !== 'uploading'))
  }, [])
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ 
    onDrop,
    noClick: true,
    noKeyboard: true
  })
  // let what = <p>Drag 'n' drop {single ? 'a file' : 'some files'} here, or click to select file{!single && 's'}</p>
  // if (isDragActive) {
  //   what = <p>Drop here ...</p>
  // }
  // if (uploading) {
  //   what = <p>Uploading ...</p>
  // }
  return (
    <div className={classNames('uploader', { active: isDragActive, uploading })} {...getRootProps()}>
      <input {...getInputProps()} />
      <button className='fa fa-file-image absolute open-icon' onClick={open} />
      {children}
    </div>
  )
}

