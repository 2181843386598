import { useEffect, useState } from "react"
import { mergeDeepLeft, omit } from 'ramda'
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { PUBLIC_IC_SERVERS, labelForUser, fetchIcfsInfo, getSigner, fetchJwt, httpDomain, cleanDomain, icUrl, getDomain } from "./lib/utils"
import * as st8 from './state'
import CopyLink from "./CopyLink"


export default function IcDetails({ p }) {
  const [perspectives, setPerspectives] = useRecoilState(st8.perspectivesState)
  const [icFileServerUser, setIcFileServerUser] = useRecoilState(st8.icFileServerUserState)
  const [makePublic, setMakePublic] = useState(false)
  const knownIcServers = useRecoilValue(st8.knownIcServersState)
  const [possibleServer, setPossibleServer] = useState('')
  const [possibleServerInfo, setPossibleServerInfo] = useState({})
  const exportData = useSetRecoilState(st8.icExportDataState)
  const userLabel = labelForUser(icFileServerUser, perspectives)

  useEffect(() => {
    setMakePublic(false)
    setPossibleServer('')
  }, [p])

  // fetch the icfs info for the possible server
  useEffect(() => {
    const cleanedPossibleServer = possibleServer.trim().replace(/https?:\/\//, '')
    if (cleanedPossibleServer !== possibleServer) {
      setPossibleServer(cleanedPossibleServer)
    }
    setPossibleServerInfo({})
    const goodServerUrl = cleanedPossibleServer.startsWith('localhost') || /\.[a-zA-Z]{2,}$/.test(cleanedPossibleServer.split('/')[0])
    if (goodServerUrl && !possibleServerInfo.loading) {
      let go
      // step 1 - fetch name
      if (!possibleServerInfo.name) {
        go = async () => {
          setPossibleServerInfo({ loading: true })
          const info = await fetchIcfsInfo(cleanedPossibleServer)
          setPossibleServerInfo(info)
        }
      }
      if (go) go()
    }
  }, [possibleServer])
  // step 2 - do icfs checks
  useEffect(() => {
    if (possibleServerInfo.name && !possibleServerInfo.loading) {
      let go
      if (!possibleServerInfo.jwt) {
        go = async () => {
          setPossibleServerInfo(mergeDeepLeft({ loading: true }))
          const signer = await getSigner(perspectives, icFileServerUser)
          const jwt = await fetchJwt(signer, possibleServerInfo.url, icFileServerUser)
          setPossibleServerInfo(mergeDeepLeft({ loading: false, jwt: jwt || 'fail' }))
        }
      } else if (!possibleServerInfo.status) {
        go = async () => {
          let res
          try {
            res = await fetch(`${httpDomain(possibleServerInfo.url)}/${icFileServerUser}/index.ic`) 
          } catch(e) {
            console.log(e)
          }
          if (!res || res.status === 404) {
            setPossibleServerInfo(mergeDeepLeft({ loading: false, status: 'not found' }))
          } else {
            setPossibleServerInfo(mergeDeepLeft({ loading: false, status: 'found' }))
          }
        }
      }
      if (go) go()
    }
  }, [possibleServerInfo, setPossibleServerInfo])

  let theOpen = null
  const knownServers = Object.keys(knownIcServers)
  const myServers = knownServers.filter(s => !PUBLIC_IC_SERVERS.includes(s))

  if (p.server) {
    const icFile = httpDomain(p.server.url) + '/' + p.address + '/index.ic'
    theOpen = (
      <div className='server'>
        <div className='server-name'>
          <p>
            🌍 This collection is public
          </p>
          <p>
            💾 It saves to {p.server.name} <span className="hint">{cleanDomain(p.server.url)}</span> <a className="pop" onClick={() => setPerspectives(
              perspectives.map((_p) => _p.address === p.address ? omit(['server'], _p) : _p)
            )}>x</a>
          </p>
          <p>
            🔗&nbsp;
            <CopyLink text={icUrl(icFile)}>The share link</CopyLink>
          </p>
          <p>
            📄&nbsp;
            {/* <CopyLink text={icFile}>The .ic file</CopyLink> */}
            <a target='_blank' href={icFile}>The .ic file</a>
          </p>
          <p>
            🕺🏻&nbsp;
            {p.server.collabs 
              ? <CopyLink text={`${window.location.protocol}//${window.location.host}?collaborate=${icFile}&auth=${p.server.collabs}`}>Your collaborators link</CopyLink>
              : <a onClick={() => {
                  const go = async () => {
                    const signer = await getSigner(perspectives, icFileServerUser)
                    const jwt = await fetchJwt(signer, httpDomain(p.server.url), icFileServerUser, {
                      // exp: parseInt((Date.now() + 1000 * 60 * 60 * 24 * 30) / 1000, 10),
                      tags: '.ic collaborators'
                    })
                    setPerspectives(
                      perspectives.map((_p) => _p.address === p.address ? mergeDeepLeft({ server: { collabs: jwt } }, _p) : _p)
                    )
                  }
                  go()
                }}>Add collaborators</a>
            }
          </p>
          <p>
            {p.server.invites 
              ? <CopyLink text={`${window.location.protocol}//${window.location.host}?invite=${p.server.url}/${p.address}&auth=${p.server.invites}`}>Your invite link</CopyLink> 
              : <a onClick={() => {
                const go = async () => {
                  const signer = await getSigner(perspectives, icFileServerUser)
                  const jwt = await fetchJwt(signer, httpDomain(p.server.url), icFileServerUser, {
                    // exp: parseInt((Date.now() + 1000 * 60 * 60 * 24 * 30) / 1000, 10),
                    tags: '.ic invites'
                  })
                  setPerspectives(
                    perspectives.map((_p) => _p.address === p.address ? mergeDeepLeft({ server: { invites: jwt } }, _p) : _p)
                  )
                }
                go()
              }}>🤝 Invite to server</a>
            }
          </p>
        </div>
      </div>
    )
        
  } else if (makePublic) {
    theOpen = (
      <div className='add-remote-server'>
        {Object.entries(possibleServerInfo).length < 1 && <div className='possible-server-info'>
          <h3>Add a remote ic server</h3>
            <input type='text' value={possibleServer} onChange={evt => setPossibleServer(evt.target.value)} />
            <ul className='options'>
              <div>dont know one? use one of mine...</div>
              {PUBLIC_IC_SERVERS.map(s => <li key={s}><a onClick={() => {
                setPossibleServer('')
                setPossibleServerInfo({})
                setPossibleServer(s)
              }}>{s}</a></li>)}
            </ul>
          </div>}
          {possibleServerInfo.name && <div className='possible-server-info'>
            <ul>
              <li>
                <p>
                  ✅ found server: {possibleServerInfo.name} <span className='hint'>{possibleServer}</span>
                  <a onClick={() => {
                    setPossibleServer('')
                    setPossibleServerInfo({})
                  }} className='fa fa-pen pl-3 text-sm no-underline' />
                </p>
                <p>{possibleServerInfo.description}</p>
              </li>
              {possibleServerInfo.jwt && <li>
                <p>
                  {possibleServerInfo.jwt === 'fail' ? '❌' : '✅'}
                  &nbsp;you can{possibleServerInfo.jwt === 'fail' && 'not'} save "{userLabel}" to it 
                </p>
                </li>
              }
              {possibleServerInfo.status && <>
                <li>
                  <p>{possibleServerInfo.status === 'found'? '👋 .ic already exists' : '🪹 no .ic here yet'}</p>
                </li>
                <li>
                  <a className='btn btn-primary' onClick={async () => {
                    setPerspectives(perspectives.map(_p => {
                      if (_p.address === p.address) {
                        _p = Object.assign({}, _p, { server: omit(['loading'], possibleServerInfo) })
                      }
                      return _p
                    }))
                    setPossibleServerInfo({})
                    setPossibleServer('')
                    window.location.reload()
                  }} >💾  Save to "{possibleServerInfo.name}"?</a>
                </li>
              </>}
            </ul>
          </div>}
      </div>
    )
  } else {
    theOpen = (
      <div className='private'>
        <p>
          🔒 This collection is private.
        </p>
        <p>
          🖥 It lives in your browser.
        </p>
        <p>
          💾 You can <a onClick={() => {
            exportData('all-file')
          }}>export it to a file.</a>
        </p>
        <p>
          🌍 You can <a onClick={() => {
            setMakePublic(true)
            // automatically add a server
            setPossibleServer('')
            setPossibleServerInfo({})
            // one day i will make this work
            // const serverList = myServers.length > 0 ? myServers : PUBLIC_IC_SERVERS 
            const serverList = PUBLIC_IC_SERVERS
            setPossibleServer(serverList[Math.floor(Math.random() * serverList.length)])
          }}>make it public</a>
        </p>

      </div>
    )
  }
  return theOpen
}